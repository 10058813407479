<nav class="navbar navbar-expand-sm navbar-light mt-4">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/imgs/garylogo.png" width="50" alt="" *ngIf="theme == 'light'" />
      <img src="assets/imgs/garylogowhite.png" width="50" alt="" *ngIf="theme != 'light'" />
    </a>


  </div>
</nav>




<div class="container mt-5">
  <div class="row text-center">
    <div class="col-lg-4  offset-lg-4 col-md-6 offset-md-3 col-sm-12">
      <h3>Forgot Password?</h3>
      <!-- <p>A Crypto Creator Community</p> -->
      <p>Enter your email to reset your password.</p>


      <ng-container *ngIf="errorState === errorStates.HasError">
        <div class="mb-lg-15 alert alert-danger">
          <div class="alert-text font-weight-bold">
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="errorState === errorStates.NoError">
        <div class="mb-10 bg-light-info p-8 rounded">
          <!--begin::Body-->
          <div class="text-info">Sent password reset. Please check your email</div>
          <!--end::Body-->
        </div>
      </ng-container>

      <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()">
        <div class="fv-row mb-10 text-start">
          <label class="form-label fw-bolder text-gray-900 fs-6">Email</label>
          <input class="form-control form-control-lg form-control-solid" type="email" formControlName="email"
            placeholder="Email" name="email" autocomplete="off" [ngClass]="{
                'is-invalid': forgotPasswordForm.controls['email'].invalid,
                'is-valid': forgotPasswordForm.controls['email'].valid
              }" />

          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'Email is required',
                control: forgotPasswordForm.controls['email']
              }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'email',
                message: 'Email is invalid',
                control: forgotPasswordForm.controls['email']
              }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'minLength',
                message: 'Email should have at least 3 symbols',
                control: forgotPasswordForm.controls['email']
              }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'maxLength',
                message: 'Email should have maximum 360 symbols',
                control: forgotPasswordForm.controls['email']
              }"></ng-container>
        </div>
        <!--  -->
        <a class="btn btn-clear" routerLink="/login">Cancel</a>
        <button class="btn btn-custom my-3" [disabled]="forgotPasswordForm.invalid">Continue</button>
      </form>

      <!-- <a [href]="'/forgot_password'">Forgot Password?</a> -->




    </div>
  </div>
</div>


<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>