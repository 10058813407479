<div class="container-fluid">
  <div class="row mt-2 mt-md-5 align-items-center">
    <div class="col-4 col-sm-3 col-md-2 col-lg-4">
      <img [src]="ecoInfo?.logo" alt="" srcset="" class="logo">
    </div>
    <div class="col-4 col-sm-6 col-md-8 col-lg-4  text-center">
      <h3 class="d-none d-md-block">{{ ecoInfo?.reward_page_name }}</h3>
    </div>
    <div class="col-4 col-sm-3 col-md-2 col-lg-4 text-end">
      <div ngbDropdown>
        <button type="button" class="btn btn-clear" id="dropdownConfig" ngbDropdownToggle>
          <img [src]="profile_pic" alt="" class="rounded-circle logo2"
            onerror="this.onerror=null; this.src='assets/imgs/badge.png';">
          <span class="icononImg">
            <i class="fa-solid fa-circle-chevron-down fa-lg"></i>
          </span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownConfig">
          <div class="text-center px-2 py-2">
            <div>
              <h4> {{ name }} </h4>
            </div>
          </div>
          <a ngbDropdownItem [href]="'/claimed_offers'"><i class="fa-sharp fa-light fa-money-bill-1-wave"></i>
            &nbsp;&nbsp;&nbsp; Redeemed
            Offers</a>
          <a ngbDropdownItem [href]="'/leaderboard'"><i class="fa-regular fa-bars-sort"></i> &nbsp;&nbsp;&nbsp;
            Leaderboard</a>
          <a ngbDropdownItem [href]="'/activities'"><i class="fa-light fa-note"></i> &nbsp;&nbsp;&nbsp;
            Activities</a>
          <a ngbDropdownItem [href]="'/settings'"><i class="fa-light fa-gear"></i> &nbsp;&nbsp;&nbsp;
            Settings</a>
          <button ngbDropdownItem (click)="app.logout()"><i class="fa-regular fa-right-from-bracket"></i>
            &nbsp;&nbsp;&nbsp; Logout</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container my-5">
  <div class="row my-3" *ngIf="ecoInfo">
    <div class="col-12 text-center">
      <h3 class=" d-block d-md-none"> {{ ecoInfo?.reward_page_name }} </h3>
    </div>
  </div>
  <div class="row ">
    <div class="col-md-4 offset-md-4 text-center">
      <p> {{ ecoInfo?.coin_label| uppercase }} BALANCE</p>
      <h3> {{ balance?.balance | number: '1.0-2' }} {{ ecoInfo?.display_symbol }} </h3>
    </div>
  </div>

  <div *ngIf="pageOffers?.length >0">
    <div class="row mt-3 " *ngFor="let item of pageOffers">
      <div class="col-lg-10 offset-lg-1 ">

        <div class="d-none d-md-flex border p-2 align-items-start rounded">
          <div class="img me-5">
            <img [src]="item?.payment_image" alt="">
          </div>
          <div class="row" style="width: 100% !important;">
            <div class="col-md-6">
              <h4 class=""> {{ item?.page_name }} </h4>
              <h6 class="mb-0"> {{ item?.amount_charge | number : '1.0-2' }} {{ ecoInfo?.display_symbol }} </h6>
            </div>
            <div class="col-md-6 text-end">
              <button class="btn btn-custom  mt-3" (click)="open(content, item)">Redeem Offer</button>
            </div>
            <div class="col-md-12 mt-3">
              <div [innerHTML]="item?.description"> </div>
            </div>
          </div>
        </div>

        <div class="d-block d-md-none border p-2 align-items-start rounded">
          <div class="d-flex mb-3">
            <img [src]="item?.payment_image" class="smllimg img-fluid" alt="...">
            <div class="ms-auto">
              <button class="btn btn-custom btn-sm" (click)="open(content, item)">Redeem Offer</button>
            </div>
          </div>
          <h5 class="mb-0"> {{ item?.page_name }} </h5>
          <h6 class=""> {{ item?.amount_charge | number : '1.0-2' }}{{ ecoInfo?.display_symbol }} </h6>
          <div [innerHTML]="item?.description"> </div>
          <!-- <div class="card">
            <img [src]="item?.payment_image" class="card-img-top" alt="...">
            <div class="card-body">
              <h4> {{ item?.page_name }} </h4>
              <h6> {{ item?.amount_charge | number : '1.0-2' }} {{ item?.cryptocurrency_type }} </h6>
              <button class="btn btn-custom btn-block mt-3" (click)="open(content, item)">Redeem Offer</button>
            </div>
          </div> -->
        </div>

        <!-- <div class="row border p-2 align-items-center rounded">
          <div class="col-lg-2">
            <div class="img me-5">
              <img [src]="item?.payment_image" alt="">
            </div>
          </div>
          <div class="col-lg-10">
            <div class="row">
              <div class="col-lg-6">
                <h6 class="mb-0"> {{ item?.page_name }} </h6>
                <h4 class="mb-0"> {{ item?.amount_charge | number : '1.0-2' }} {{ item?.cryptocurrency_type }} </h4>
              </div>
              <div class="col-lg-6 text-end">
                <button class="btn btn-custom  mt-3" (click)="open(content, item)">Redeem Offer</button>
              </div>
            </div>
            <div class="text">
              <div [innerHTML]="item?.description"> </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div class="col-md-10 offset-md-1">
        <div class="d-none d-md-flex border p-2 align-items-start rounded">
          <div class="img me-5">
            <img [src]="item?.payment_image" alt="">
          </div>
          <div class="d-block">
            <h6 class="mb-0"> {{ item?.amount_charge | number : '1.0-2' }} {{ item?.cryptocurrency_type }} </h6>
            <h4> {{ item?.page_name }} </h4>
          </div>
          <div class="ms-auto me-5">
            <button class="btn btn-custom" (click)="open(content, item)">Redeem Offer</button>
          </div>
        </div>
        <div class="d-block d-md-none">
          <div class="card">
            <img [src]="item?.payment_image" class="card-img-top" alt="...">
            <div class="card-body">
              <h4> {{ item?.page_name }} </h4>
              <h6> {{ item?.amount_charge | number : '1.0-2' }} {{ item?.cryptocurrency_type }} </h6>
              <button class="btn btn-custom btn-block mt-3" (click)="open(content, item)">Redeem Offer</button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="row" *ngIf="startloading">
    <div class="col-12 text-center mt-5">
      <!-- <i class="fa-solid fa-rotate-reverse fa-spin fa-2xl"></i> -->
      <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
    </div>
  </div>

  <div class="row mt-5" *ngIf=" !startloading && pageOffers?.length <=0">
    <div class="col-12 mt-5 text-center">
      There are no offers available
    </div>
  </div>


</div>


<ng-template #content let-modal>

  <div class="modal-body">
    <div class="text-end">
      <button type="button" class="btn btn-clear" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <i class="fa-duotone fa-xmark-large"></i></button>
    </div>
    <div class="text-center m-5">
      <h3>{{ item?.page_name }}</h3>
      <p>You are about to redeem {{ item?.amount_charge | number : '1.0-2' }} {{ item?.cryptocurrency_type }} of
        your {{ balance?.balance | number: '1.0-2' }} {{ ecoInfo?.display_symbol }} balance</p>
      <p *ngIf="item?.amount_charge > balance?.balance" class="text-danger">
        Your balance is too low to claim this offer</p>
      <div class="mt-5">
        <button class="btn btn-outline-primary me-3 mb-3" (click)="modal.close('Save click')">Cancel</button>
        <button class="btn btn-custom mb-3 me-3"
          [disabled]="loading === true  ||item?.amount_charge > balance?.balance " (click)="makePayment()">
          <ng-container *ngIf="loading">
            <span class="indicator-progress" [style.display]="'block'">
              Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </ng-container>
          <ng-container *ngIf="loading === false">
            <span class="indicator-label">Confirm</span>
          </ng-container>
        </button>
        <!-- <button class="btn btn-custom" (click)="makePayment()">Confirm</button> -->
      </div>
    </div>
  </div>

</ng-template>
