import { AppService } from 'src/app/_services/app.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPasswordValidator } from '../confirm-password-validator';

enum ErrorStates {
  NotSubmitted,
  HasError,
  NoError,
}

@Component({
  selector: 'app-modal-content',
  template: `
    <div class="modal-body text-center p-4">
      <span class="svg-icon svg-icon-primary svg-icon-5tx"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.3"
            x="2"
            y="2"
            width="20"
            height="20"
            rx="10"
            fill="currentColor"
          />
          <path
            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <br /><br />
      <h3>Success!!!</h3>
      <p>{{ message }}</p>
      <br /><br />
      <button type="button" class="btn btn-primary mb-5" (click)="continue()">
        Back to Login
      </button>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="continue()">
        Proceed to Dashboard
      </button>
    </div> -->
  `,
})

export class NgbdModalContent {
  @Input() message: any;
  constructor(
    config: NgbModalConfig,
    public activeModal: NgbActiveModal,
    public modal: NgbModal,
    private router: Router
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  continue() {
    // //console.log('yaaay');
    this.modal.dismissAll();
    this.router.navigateByUrl('/');
  }
}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm!: FormGroup;
  errorState: ErrorStates = ErrorStates.NotSubmitted;
  errorStates = ErrorStates;
  isLoading$!: Observable<boolean>;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  hasError!: boolean;
  email!: string | null;
  message: any;
  theme!: string;
  constructor(
    private fb: FormBuilder,
    private settings: AppService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getTheme()
    this.initForm();
    this.email = localStorage.getItem('email');
  }

  getTheme() {
    if (localStorage.getItem('theme') == null) {
      this.theme = 'dark-theme';
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    } else {
      this.theme = localStorage.getItem('theme') || 'light';
    }

  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  initForm() {
    this.resetPasswordForm = this.fb.group(
      {
        code: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(10),
          ]),
        ],
        email: [this.email],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
          ]),
        ],
        password_confirmation: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
          ]),
        ],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }

  submits() {
    this.errorState = ErrorStates.NotSubmitted;
    // //console.log(
    //   this.f.code.value,
    //   this.f.password.value,
    //   this.f.password_confirmation.value
    // );
  }

  submit() {
    this.hasError = false;
    const data = {
      email: this.email,
      code: this.f['code'].value,
      password: this.f['password'].value,
      password_confirmation: this.f['password_confirmation'].value,
    };
    // //console.log(data);
    const rstSubscr = this.settings
      .resetPassword(data)
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          if (result.success == true) {
            this.message = result.message;
            this.router.navigate(['/']);
            // //console.log(data);
            localStorage.removeItem('email');
          } else {
            this.hasError = true;
          }
        },
        complete: async () => {
          this.unsubscribe.push(rstSubscr);
          // const modalRef = this.modalService.open(NgbdModalContent);
          // modalRef.componentInstance.message = this.message;
          Swal.fire('success', this.message, 'success');
        },
        error: (err: any) => {
          if (err.error.errors.password) {
            Swal.fire('Failed!', err.error.errors.password[0], 'error');
          } else {
            Swal.fire('Failed!', err.error.message, 'error');
          }
        },
      });
  }

}
