import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppService } from '../_services/app.service';
import Swal from 'sweetalert2';
import { BehaviorSubject, Observable, Subscription, first } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from '../auth/confirm-password-validator';
import { TitleCasePipe } from '@angular/common';
import { EventService } from '../_services/events.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavigationService } from '../_services/navigations.service';
export type UserType = any | undefined;
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [TitleCasePipe],
})
export class SettingsComponent implements OnInit {
  userDetails: any;
  active = 'top';
  navPosition: number = 1;
  user$!: Promise<any>;
  profile_pic = 'assets/imgs/avatar2.png';
  name = ''
  social_tag = ''
  email = ''
  password = ''
  bio: any;
  imageErrValue!: string;
  imageError!: boolean;
  qqq!: boolean;
  isLoading$: Observable<boolean> | undefined;
  hasError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private unsubscribe: Subscription[] = [];
  nameMaxLength = 30
  maxlengthreached: any;
  showPass = false;
  showPass2 = false;
  changePasswordForm!: FormGroup;
  changeMailForm!: FormGroup;

  // selectedFile: any;
  imgFile: any = 'https://via.placeholder.com/150';
  selectedFile!: ImageSnippet;
  test: any;
  profileUrl: any = 'https://via.placeholder.com/150';
  message: any;

  maxFileSize = 3 * 1024 * 1024;
  socialForm: any;
  minForm: any;
  minimum_note_amount: any;
  showUpdateEmail: boolean = false;
  manageInstagramBusinessAccessGranted!: boolean;
  showLB: boolean = false;
  coin_buy_notification: boolean = false;
  transfer_notification: boolean = false;
  airdrop_notification: boolean = false;
  chactive: boolean = false;
  telactive: boolean = false;
  tiktokactive: boolean = false;
  chImg!: string;
  discordactive: boolean = false;
  // show_eth: boolean;
  discordImg!: string;
  twitterActive: boolean = false;
  solActive: boolean = false;
  ethActive: boolean = false;
  twitterlink: any;
  telegramLink: any;
  discord_data: any;

  form: any = {
    ch_username: null,
  };
  ch_user_id: any;
  closeResult!: string;
  // user$!: Observable<UserType>;
  sollink: any;
  instagramlink: any;
  ig_activated: boolean = false;
  currentEnv: any;
  isVerified: boolean = false;
  instagram_data: any;
  insta_check: any;
  instagrambusinesslink: any;
  // manageInstagramBusinessAccessGranted: boolean = false;
  activity_name: any;
  activity_result: any;
  instagram_business_username: any;
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading!: boolean;
  previousRoute: string | null = null;
  constructor(
    public app: AppService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private titlecasePipe: TitleCasePipe,
    private evtSvc: EventService,
    private route: ActivatedRoute,
    private router: Router,
    private navigation: NavigationService
  ) {
    this.isLoading$ = this.app.isLoading$;
    const loadingSubscr = this.isLoading$$
      .asObservable()
      .subscribe((res) => (this.isLoading = res));
    this.unsubscribe.push(loadingSubscr);

    const append_route = this.route.snapshot.paramMap.get('reference');
    if (append_route !== null) {
      this.navPosition = 3;
      this.handleFailorSuccess(append_route?.split("_"))
    }
  }

  getPreviousUrl() {
    this.previousRoute = this.navigation.getPreviousUrl();
    console.log(this.navigation.getPreviousUrl())
  }

  handleFailorSuccess(data: any) {
    this.activity_name = data[0] ?? null;
    this.activity_result = data[1] ?? null;
    if (this.activity_result === 'success') {
      Swal.fire('', `You have successfully linked your ${this.activity_name} account!`, 'success');
    } else if (this.activity_result === 'duplicate') {
      Swal.fire('', `The ${this.activity_name} account you are trying to link is already linked to another account.`, 'error');
    } else if (this.activity_result != null) {
      Swal.fire('', `An error occured while linking your ${this.activity_name} account.`, 'error');
    }
  }

  ngOnInit(): void {

    this.getPreviousUrl()
    console.log('Previous', this.previousRoute)
    this.getUser()

    this.initform()


  }

  getUser() {
    this.app.getUserDetails().subscribe({
      next: (res: any) => {
        this.user$ = this.app.getUser().then((res: any) => {
          // console.log(res)
          this.userDetails = res;
          this.insta_check = res;
          if (res?.sc_profile_picture !== '') {
            this.profileUrl = res?.sc_profile_picture
            this.imgFile = res?.sc_profile_picture;
            this.name = res?.name
            this.email = res?.email
            this.social_tag = res?.social_tag
            // console.log(this.imgFile)
            this.checkManage(this.userDetails?.permissions);

            console.log('users details re naw', this.userDetails);
            if (this.userDetails?.instagram_username !== null) {
              this.instagram_data = this.userDetails?.instagram_username
            }
            if (this.userDetails?.instagram_business_username !== null) {
              this.instagram_business_username = this.userDetails?.instagram_business_username;
            }
            if (this.userDetails?.discord_data !== '') {
              this.discord_data = JSON.parse(this.userDetails?.discord_data);
            }
            this.ch_user_id = this.userDetails.ch_token;
            if (this.userDetails?.coin_buy_notification === 1) {
              this.coin_buy_notification = true;
            } else {
              this.coin_buy_notification = false;
            }
            if (this.userDetails.show_leaderboard === 'true') {
              // //console.log(this.userDetails.show_leaderboard);
              this.showLB = true;
            } else {
              this.showLB = false;
            }

            if (this.userDetails.transfer_notification === 1) {
              this.transfer_notification = true;
            } else {
              this.transfer_notification = false;
            }

            if (this.userDetails.airdrop_notification === 1) {
              this.airdrop_notification = true;
            } else {
              this.airdrop_notification = false;
            }

            if (this.userDetails.ch_user_id !== null) {
              this.chactive = true;
              // //console.log(this.userDetails.ch_user_id);
            } else {
              this.chactive = false;
            }
            if (this.userDetails.telegram_user_id !== null) {
              this.telactive = true;
              // //console.log(this.userDetails.ch_user_id);
            } else {
              this.telactive = false;

            } if (this.userDetails.tiktok_user_id !== null) {
              this.tiktokactive = true;
              // //console.log(this.userDetails.ch_user_id);
            } else {
              this.tiktokactive = false;

            }
            if (
              this.userDetails.discord_id === '' ||
              this.userDetails.discord_id === null
            ) {
              this.discordactive = false;
              // this.show_eth = false;
            } else {
              // this.show_eth = true;
              this.discordactive = true;
            }
            if (
              this.userDetails.twitter_username === '' ||
              this.userDetails.twitter_username === null ||
              this.userDetails.twitter_username == undefined
            ) {
              this.twitterActive = false;
            } else {
              this.twitterActive = true;
            }

            if (this.userDetails.solana_address === null) {
              this.solActive = false;
            } else {
              this.solActive = true;
            }
            if (this.userDetails.ethereum_address == null) {
              this.ethActive = false;
            } else {
              this.ethActive = true;
            }

            if (this.userDetails?.ig_user_id === null) {
              this.ig_activated = false
            } else {
              this.ig_activated = true
            }
          }
        })
      }
    })
  }

  // getUser() {
  //   const a: any = this.app.getUser()

  //   const b = setInterval(() => {
  //     const a = this.app.getUser();

  //   }, 1000);
  // }

  checkManage(a: any) {
    // check access to social pay module section start
    const manageInstagramBusinessAccess = a.find((obj: any) => {
      return obj.name === 'manage_instagram';
    });
    this.manageInstagramBusinessAccessGranted = !!manageInstagramBusinessAccess;
    // check access to social pay module section ends
    // const first = a.find((obj: any) => {
    //   return obj.name === 'manage_team';
    // });
    // this.manageTeam = !!first;
  }
  initform() {
    this.changePasswordForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
          ]),
        ],
        password_confirmation: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
          ]),
        ],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );

    this.changeMailForm = this.fb.group(
      {
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            Validators.maxLength(320),
          ]),
        ],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
          ]),
        ],

      },
    );
  }

  setNav(num: number) {
    this.navPosition = num
  }

  updateName() {
    console.log(this.name)
  }

  processFile(imageInput: any) {
    this.imageErrValue = "";
    this.imageError = false;
    this.qqq = true;
    this.cdr.detach();
    const file: File = imageInput.files[0];

    if (file && file.size <= this.maxFileSize) {
      this.imageError = false;
      const reader = new FileReader();

      reader.addEventListener('load', (event: any) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          this.selectedFile = new ImageSnippet(event.target.result, file);
          this.imgFile = event.target.result;
          this.imageError = false;

          this.cdr.detectChanges();
        };
      });
      reader.readAsDataURL(file);
      setTimeout(() => {
        this.upload()
      }, 3000);
    } else {
      this.imageError = true;
      this.imageErrValue = "File size should not exceed 3mb"
      console.log('Image is too big');
      this.imgFile = null;
    }

    this.cdr.reattach();
  }

  Submit() {

    // this.hasError.next(true);
    this.isLoading$$.next(true);
    const social_tag = this.social_tag;
    const data = { social_tag: this.social_tag }
    // console.log(social_tag)
    // const profile_picture = this.test;
    // //console.log('profiole', profile_picture, 'name', name);
    this.app
      .socialTag(data)
      .subscribe({
        next: (res: any) => {
          console.log(res);
          Swal.fire('Success!', res.message, 'success');
        },
        complete: () => {
          this.app.getUserDetails().subscribe((rs) => {
            this.getUser();
            // this.modalService.dismissAll()
          });
          // window.location.reload();
          this.isLoading$$.next(false);
          this.cdr.detectChanges();
        },
        error: (err: any) => {
          Swal.fire('Failed', err.error.message, 'error')
          this.isLoading = false;
          this.isLoading$$.next(false);
        },
      });
  }

  upload() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn  btn-outline-danger',
      },
      buttonsStyling: false,
    });
    const name = this.name;
    const bio = this.bio;

    if (this.selectedFile === undefined || this.selectedFile === null) {
      this.test = this.imgFile;
    } else {
      this.test = this.selectedFile.file;
    }
    console.log(this.imgFile)
    const profile_picture = this.test;
    // console.log(city, country)
    console.log(name, bio, profile_picture)
    this.app
      .updateProfile(profile_picture, name, bio)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          if (res.success === true) {
            // const modalRef = this.modalService.open(NgbdModalContent);
          }
        }, complete: () => {

          let mssg = 'Changes made successfully';
          swalWithBootstrapButtons.fire('Success!', mssg, 'success').then(() => {
            window.location.reload()
          })


          this.cdr.detectChanges();
        },
        error: (err: any) => {
          this.message = err.error.message;
          this.cdr.detectChanges();
          if (err.error.errors?.name) {
            swalWithBootstrapButtons.fire(
              'Failed!',
              err.error.errors?.name[0],
              'error'
            );
          } else if (err.error.errors?.profile_picture) {
            swalWithBootstrapButtons.fire(
              'Failed!',
              err.error.errors?.profile_picture[0],
              'error'
            );
          } else {
            swalWithBootstrapButtons.fire(
              'Failed!',
              err.error.message,
              'error'
            );
          }
        }
      });
  }


  changeEmail() {
    this.showUpdateEmail = true
  }

  cancel() {
    this.showUpdateEmail = false
  }

  get P() {
    return this.changePasswordForm.controls;
  }
  get f() {
    return this.changeMailForm.controls;
  }


  savePassword() {

    const result: {
      [key: string]: string;
    } = {};
    Object.keys(this.P).forEach((key) => {
      result[key] = this.P[key].value;
    });
    const a = {
      password: result['password'],
      password_confirmation: result['password_confirmation'],
    };
    //console.log(a);
    const changePsSubscr = this.app
      .updatePassword(a)
      .pipe(first())
      .subscribe({
        next: (rs) => {
          {
            if (rs) {
              this.app.getUserDetails();
              // const modalRef = this.modalService.open(NgbdModalContent);
              let mssg = rs.message;
              mssg = this.titlecasePipe.transform(mssg);
              // modalRef.componentInstance.name = mssg;
              // this.showChangePasswordForm = false;
              Swal.fire('Success', mssg, 'success')
              this.cdr.detectChanges();
            } else {
              this.hasError.next(true);
            }
          }
        },
        complete: () => {

          // this.app.getUserDetails().subscribe()
          this.ngOnInit()
          this.cdr.detectChanges();
        },
        error: (err) => {
          // this.isLoading$$.next(false);
          this.message = err.error.message;
          this.hasError.next(true);
          Swal.fire('Failed', err.error.message, 'error')
          // //console.log(err);
        },
      });
    this.unsubscribe.push(changePsSubscr);
  }


  saveEmail() {
    this.hasError.next(false);
    const result: {
      [key: string]: string;
    } = {};
    Object.keys(this.f).forEach((key) => {
      result[key] = this.f[key].value;
    });
    const a = {
      email: result['email'],
      password: result['password'],
    };
    console.log(a)
    const changeMailSubscr = this.app
      .changeEmail(a)
      .pipe(first())
      .subscribe({
        next: (user) => {
          console.log('user re', user);
          if (user) {
            this.app.getUserDetails();
            let mssg = user.message;
            mssg = this.titlecasePipe.transform(mssg);
            // Swal.fire('Success', mssg, 'success')
            this.showUpdateEmail = false;
            this.cdr.detectChanges();
          } else {
            this.hasError.next(true);
          }
        },
        error: (err) => {
          console.log('error re', err);
          // this.message = err.error.message;
          // Swal.fire('Failed', err.error.message, 'error')
          this.hasError.next(true);
        },
        complete: () => {
          this.ngOnInit()
          this.cdr.detectChanges();
        },
      });
    this.unsubscribe.push(changeMailSubscr);
  }



  unlinkTwitter() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'ms-3 btn btn-primary',
        cancelButton: ' me-3btn  btn-outline-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to unlink your Twitter Account?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const detailsSubscr = this.app.unlinkTwitter().subscribe({
            next: (rs: any) => {
              if (rs) {
                //  //console.log(rs);
                this.app.getUserDetails();
                let mssg = rs.message;
                mssg = this.titlecasePipe.transform(mssg);
                swalWithBootstrapButtons.fire('Unlinked!', mssg, 'success');
                this.cdr.detectChanges();
              }
            },
            complete: () => {
              this.isLoading$$.next(false);

              this.app.getUserDetails().subscribe((rs) => {
                this.evtSvc.emitChildEvent('true');
                this.getUser();
              });
              this.cdr.detectChanges();
            },
            error: (err: any) => {
              this.isLoading$$.next(false);
              this.message = err.error.message;
              this.hasError.next(true);
              // //console.log(err);
            },
          });
          this.unsubscribe.push(detailsSubscr);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your account is safe :)',
            'error'
          );
        }
      });
  }

  linkTwitter() {
    const detailsSubscr = this.app.linkTwitter().subscribe({
      next: (res: any) => {
        // //console.log(res);
        if (res.success === true) {
          this.twitterlink = res.data.link;
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.href = this.twitterlink;
          a.click();
          document.body.removeChild(a);
        }
      },

      error: (err: any) => {
        this.isLoading$$.next(false);
        this.message = err.error.message;
        this.hasError.next(true);
        // //console.log(err);
      },
    });
    this.unsubscribe.push(detailsSubscr);
  }


  linkTelegram() {
    const detailsSubscr = this.app.linkTelegram().subscribe({
      next: (res: any) => {
        // //console.log(res);
        if (res.success === true) {
          this.telegramLink = res.data.link;
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.href = this.telegramLink;
          a.click();
          document.body.removeChild(a);
        }
      },

      error: (err: any) => {
        this.isLoading$$.next(false);
        this.message = err.error.message;
        this.hasError.next(true);
        // //console.log(err);
      },
    });
    this.unsubscribe.push(detailsSubscr);
  }
  unlinkTelegram() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn  btn-outline-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to unlink your Telegram Account?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const detailsSubscr = this.app.unlinkTelegram().subscribe({
            next: (rs: any) => {
              if (rs) {
                //  //console.log(rs);
                this.app.getUserDetails();
                let mssg = rs.message;
                mssg = this.titlecasePipe.transform(mssg);
                swalWithBootstrapButtons.fire('Unlinked!', mssg, 'success');
                this.cdr.detectChanges();
              }
            },
            complete: () => {
              this.isLoading$$.next(false);

              this.app.getUserDetails().subscribe((rs) => {
                this.evtSvc.emitChildEvent('true');
                this.getUser();
              });
              this.cdr.detectChanges();
            },
            error: (err: any) => {
              this.isLoading$$.next(false);
              this.message = err.error.message;
              this.hasError.next(true);
              // //console.log(err);
            },
          });
          this.unsubscribe.push(detailsSubscr);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your account is safe :)',
            'error'
          );
        }
      });
  }
  unlinkDiscord() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn  btn-outline-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to unlink your Discord Account?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const unlinkDiscordSubscr = this.app.unlinkDiscord().subscribe({
            next: (rs: any) => {
              if (rs) {
                // //console.log(rs);
                this.app.getUserDetails();
                // const modalRef = this.modalService.open(NgbdModalContent);
                // let mssg = rs.message;
                // mssg = this.titlecasePipe.transform(mssg);
                // modalRef.componentInstance.name = mssg;
                this.cdr.detectChanges();
              }
            },
            complete: () => {
              swalWithBootstrapButtons.fire(
                'Unlinked!',
                'Your Discord has been unlinked.',
                'success'
              );
              this.isLoading$$.next(false);
              this.app.getUserDetails().subscribe((rs) => {
                this.evtSvc.emitChildEvent('true');
                this.getUser();
              });
              this.cdr.detectChanges();
            },
            error: (err: any) => {
              this.isLoading$$.next(false);
              swalWithBootstrapButtons.fire(
                'Failed!',
                err.error.message,
                'error'
              );
              this.message = err.error.message;
              this.hasError.next(true);
              // //console.log(err);
            },
          });
          this.unsubscribe.push(unlinkDiscordSubscr);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your account is safe :)',
            'error'
          );
        }
      });
  }

  linkDiscord() {
    const linkDiscordSubscr = this.app.linkDiscord().subscribe({
      next: (res: any) => {
        // //console.log(res);
        if (res.success === true) {
          this.twitterlink = res.data.link;
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.href = this.twitterlink;
          a.click();
          document.body.removeChild(a);
        }
      },
      error: (err: any) => {
        this.isLoading$$.next(false);
        this.message = err.error.message;
        this.hasError.next(true);
        // //console.log(err);
      },
    });
    this.unsubscribe.push(linkDiscordSubscr);
  }


  linkInstagram() {
    const linkInstagramSubscr = this.app.linkInstagram().subscribe({
      next: (res: any) => {
        console.log('response', res);
        if (res?.success === true) {
          this.instagramlink = res?.data?.link;
          console.log('instagram', this.instagramlink);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.href = this.instagramlink;
          a.click();
          document.body.removeChild(a);
          this.refreshUserdetails();
        }
      },
      error: (err: any) => {
        this.isLoading$$.next(false);
        this.message = err?.error?.message;
        this.hasError.next(true);
        // //console.log(err);
      },
    });
    this.unsubscribe.push(linkInstagramSubscr);
  }
  unlinkInstagram() {
    const unlinkInstagramSubscr = this.app.unlinkInstagram().subscribe({
      next: (res: any) => {
        // //console.log(res);
        if (res.success === true) {
          Swal.fire('Success!', res?.message, 'success');
          this.ig_activated = !this.ig_activated
          this.refreshUserdetails();
        }
      },
      error: (err: any) => {
        this.isLoading$$.next(false);
        this.message = err.error.message;
        this.hasError.next(true);
        // //console.log(err);
      },
    });
    this.unsubscribe.push(unlinkInstagramSubscr);
  }
  linkInstagramBusiness() {
    this.app.linkInstagramBusiness().subscribe({
      next: (res: any) => {
        console.log('response', res);
        if (res?.success === true) {
          this.instagrambusinesslink = res?.data?.link;
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.href = this.instagrambusinesslink;
          a.click();
          document.body.removeChild(a);
          this.refreshUserdetails();
        }
      },
      error: (err: any) => {
        this.isLoading$$.next(false);
        this.message = err?.error?.message;
        this.hasError.next(true);
        // //console.log(err);
      },
    });
    // this.unsubscribe.push(linkInstagramSubscr);
  }
  unlinkInstagramBusiness() {
    this.app.unlinkInstagramBusiness().subscribe({
      next: (res: any) => {
        console.log(res);
        if (res.success === true) {
          Swal.fire('Success!', res?.message, 'success');
          // this.ig_activated = !this.ig_activated
          this.refreshUserdetails();
          window.location.reload();
        }
      },
      error: (err: any) => {
        this.isLoading$$.next(false);
        this.message = err.error.message;
        this.hasError.next(true);
        // //console.log(err);
      },
    });
    // this.unsubscribe.push();
  }

  refreshUserdetails() {
    const detailsSubscr = this.app.getUserDetails().subscribe({
      next: (res: any) => {
        // this.userDetails = res;
        //console.log(res);
        if (res.email_verified_at == null) {
          this.isVerified = false;
        }
      },
    });
    this.unsubscribe.push(detailsSubscr);
  }


  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
