import { Component, OnInit, Inject } from '@angular/core';
import { AppService } from './_services/app.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title: string = '';
  theme: string | undefined;
  ecoInfo: any;
  constructor(
    public service: AppService,
    private titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) { }

  ngOnInit(): void {
    this.getTheme()
    this.getEcoInfo();
  }

  getTheme() {
    if (localStorage.getItem('theme') == null) {
      this.theme = 'dark-theme';
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    } else {
      this.theme = localStorage.getItem('theme') || 'light';
    }

  }

  getEcoInfo() {
    this.service.getecoinfoPublic().subscribe({
      next: (res: any) => {
        console.log('eco info', res)
        this.ecoInfo = res.data
      },
      error: (err: any) => {
        setTimeout(() => {
          this.getEcoInfo()
        }, 3000);
      },
      complete: () => {
        this.title = this.ecoInfo?.reward_page_name;
        this.titleService.setTitle(this.title)
        // this.metaServ

        this._document.getElementById('appFavicon')?.setAttribute('href', this.ecoInfo?.logo);



      }
    })
  }
}


