import { Component, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service'
// import { AlertService } from '@full-fledged/alerts';
import { LoadingBarService } from '@ngx-loading-bar/core';
// import { WalletService } from '../_services/wallet.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '../../../environments/environment'
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  joinForm!: FormGroup;
  private authLocalStorageToken = `eco_user`;
  private userLocalStorageToken = `eco_userdetails`;
  returnUrl: any;
  theme!: string;
  referral: any;
  weekly_rewards: any;
  socCon = environment.socConnUrl;
  loginFormClass: boolean = true;
  showOtp!: boolean;
  hasError!: boolean;
  status: any;
  dasabled!: boolean;
  otp!: string;
  origin: string | undefined;
  showPass = false;
  ecoInfo: any;
  namefromRoute: any;
  emailfromRoute: any;
  constructor(
    public service: AppService,
    // private alertService: AlertService,
    private loadingBar: LoadingBarService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    let userdata = this.service.getAuthFromLocalStorage();

    this.returnUrl =
      this.route.snapshot.queryParams['return'.toString()] || '/home';
    if (userdata) {
      console.log('already logged in', userdata);
      this.router.navigate([this.returnUrl]);
      if (userdata !== null || userdata !== undefined) {
        if (userdata?.data?.customer?.email_verified_at !== null) {
          this.returnUrl =
            this.route.snapshot.queryParams['return'.toString()] || '/home';
          this.router.navigate([this.returnUrl]);
        }
        else {
          this.router.navigateByUrl('/verify-email');
        }
      }
    } else {
      console.log('Need to logged in', userdata);
    }
    if (this.returnUrl && this.returnUrl.startsWith('/')) {
      this.referral = this.returnUrl.substring(1);
    }
    // this.referral = this.route.snapshot.paramMap.get('ref');

    this.route.queryParams.subscribe(params => {
      this.namefromRoute = params['name'];
      this.emailfromRoute = params['email'];

      console.log('Name:', this.namefromRoute);
      console.log('Email:', this.emailfromRoute);
    });
    this.initForm()

  }

  // weeklyCreatorRewards() {
  //   this.service.weeklyCreatorRewards().subscribe({
  //     next: (res: any) => {
  //       console.log(res)
  //       this.weekly_rewards = res.weekly_gary_reward
  //     }
  //   })
  // }
  cancel() {
    // this.modalService.dismissAll();
    this.loginFormClass = true;
  }



  ngOnInit(): void {
    this.getEcoInfo()

    this.origin = window.location.origin
    // this.getTheme()
    // this.service.getClass()
    // this.weeklyCreatorRewards()
  }

  initForm() {
    this.joinForm = this.fb.group({
      email: [
        this.emailfromRoute || '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320),
        ]),
      ],
      password: ['', Validators.required],

    });
  }

  login() {
    const payload = {
      email: this.joinForm.value.email,
      password: this.joinForm.value.password,
      ecosystem: this.origin
      // service: 'event'
    }
    console.log(payload)

    this.service.login(payload).subscribe({
      next: async (res: any) => {
        console.log(res)
        if (res.data) {
          const token = res.data.token
          this.getUserByToken(token);
          if (res?.data?.otp_required === true) {
            this.loginFormClass = false;
            this.showOtp = true;
          }

          if (res?.data?.customer?.email_verified_at === null) {
            this.router.navigateByUrl('/verify-email');
          } else if (res?.data?.customer?.email_verified_at !== null) {
            // console.log(res)

            const result = this.service.setAuthFromLocalStorage(res);
            // this.continueProcess(res)
          } else {
            this.hasError = true;
          }


        }
        else {
          if (res?.error) {
            Swal.fire('Error', res?.error?.message, 'error');
          }
        }

      },
      error: (err: any) => {
        console.log(err)

        Swal.fire('', err.error.message, 'error')
      },
      complete: () => {
      }
    })
  }

  getUserByToken(token: string) {
    this.service.getUserByToken(token).subscribe({
      next: (res: any) => {
        this.service.saveUserToStorage(res)

      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        location.reload();
        // this.router.navigate([this.returnUrl]);

      }
    })
  }

  onCodeChanged(code: string) { }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.dasabled = false;
    this.otp = code;
  }
  get f() {
    return this.joinForm.controls;
  }


  verifyOtp() {
    let payload = {
      email: this.f['email'].value,
      password: this.f['password'].value,
      code: this.otp
    }
    const loginSubscr = this.service
      .login(payload)
      .subscribe({
        next: (user: any) => {
          console.log(user)
          if (user?.success === true) {
            if (user?.data?.customer?.email_verified_at == null) {
              this.router.navigateByUrl('/verify-email');
            }
            // this.router.navigate(['/home']);
            // console.log('all is fine here', user)

            const token = user.data.token
            this.getUserByToken(token);
          } else {
            // console.log('error 1 here')
            Swal.fire('error', user?.error?.message, 'error');
          }
        },
        error: (err: any) => {
          // console.log('failed error')
          Swal.fire('Error', err.error.message, 'error')
        }
      });
    // this.unsubscribe.push(loginSubscr);
  }

  getEcoInfo() {
    this.service.getecoinfoPublic().subscribe({
      next: (res: any) => {
        console.log('eco info', res)
        this.ecoInfo = res.data
      }
    })
  }
}
