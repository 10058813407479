import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
// import { AuthService } from '../../services/auth.service';
// import { ConfirmPasswordValidator } from './confirm-password.validator';
// import { UserModel } from '../../models/user.model';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppService } from 'src/app/_services/app.service';
// import { TokenService } from '../../services/token.service';
// import { SettingsService } from 'src/app/modules/account/settings.service';
// import { CompleteregService } from 'src/app/modules/completereg/completereg.service';

export const passwordMatchingValidatior: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  return password?.value === confirmPassword?.value
    ? null
    : { notmatched: true };
};
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registrationForm!: FormGroup;
  hasError!: boolean;
  isLoading$!: Observable<boolean>;
  showPass = false;

  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  status: any;
  returnUrl: any;
  referral: any;
  totalBalance: any;
  solBalance: any;
  solPrice: any;
  custodian_wallet: any = [];
  theme!: string;
  origin!: string;
  ecoInfo: any;
  emailfromRoute: any;
  namefromRoute: any;

  constructor(
    private service: AppService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    let userdata = this.service.getAuthFromLocalStorage();
    this.returnUrl =
      this.route.snapshot.queryParams['return'.toString()] || '/home';
    if (userdata) {
      console.log('already logged in', userdata);
      this.router.navigate([this.returnUrl]);
      if (userdata !== null || userdata !== undefined) {
        if (userdata?.data?.customer?.email_verified_at !== null) {
          this.returnUrl =
            this.route.snapshot.queryParams['return'.toString()] || '/home';
          this.router.navigate([this.returnUrl]);
        } else {
          this.router.navigateByUrl('/verify-email');
        }
      }
    } else {
      console.log('Need to logged in', userdata);
    }
    if (this.returnUrl && this.returnUrl.startsWith('/')) {
      this.referral = this.returnUrl.substring(1);
    }
    this.referral = this.route.snapshot.paramMap.get('ref');
    if (this.referral === 'home') {
      this.referral = ''
    }


    this.route.queryParams.subscribe(params => {
      this.namefromRoute = params['name'];
      this.emailfromRoute = params['email'];

      console.log('Name:', this.namefromRoute);
      console.log('Email:', this.emailfromRoute);
    });

    this.initForm();
  }

  getTheme() {
    if (localStorage.getItem('theme') == null) {
      this.theme = 'dark-theme';
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    } else {
      this.theme = localStorage.getItem('theme') || 'light';
    }

  }

  ngOnInit(): void {
    this.getEcoInfo()
    this.origin = window.location.origin
  }

  get f() {
    return this.registrationForm.controls;
  }

  initForm() {
    this.registrationForm = this.fb.group(
      {
        name: [
          this.namefromRoute || '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        social_tag: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        email: [
          this.emailfromRoute || '',
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
          ]),
        ],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
          ]),
        ],
        referral_code: [
          this.referral,
          Validators.compose([
            Validators.minLength(1),
            Validators.maxLength(100),
          ]),
        ],

        agree: [false, Validators.compose([Validators.required])],
      }
      // {
      //   validator: ConfirmPasswordValidator.MatchPassword,
      // }
    );
  }

  submit() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton:
          'btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger',
      },
      buttonsStyling: false,
    });
    this.hasError = false;
    const result: {
      [key: string]: string;
    } = {};
    Object.keys(this.f).forEach((key) => {
      result[key] = this.f[key].value;
    });
    // const newUser = new UserModel();
    const newUser = result;
    const a = {
      name: result['name'],
      social_tag: result['social_tag'],
      email: result['email'],
      password: result['password'],
      referral_code: result['referral_code'],
      ecosystem: this.origin
    };
    const data = {
      email: result['email'],
      password: result['password'],
      ecosystem: this.origin
    }
    console.log(a)
    const registrationSubscr = this.service.registration(a).subscribe({
      next: (res: any) => {
        console.log(res)
        this.service.login(data).subscribe({
          next: async (res: any) => {
            console.log(res)
            const token = res.data.token
            this.getUserByToken(token);
            const result = this.service.setAuthFromLocalStorage(res);
          },
          error: (err: any) => {
            console.log(err)

            Swal.fire('', err.error.message, 'error')
          },
          complete: () => {
          }
        })
        // this.continueProcess(res)
      },
      error: (err: any) => {
        Swal.fire('Failed', err.error.message, 'error');
      },
    });
    this.unsubscribe.push(registrationSubscr);
  }

  getUserByToken(token: string) {
    this.service.getUserByToken(token).subscribe({
      next: (res: any) => {
        this.service.saveUserToStorage(res)
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        location.reload();
        // this.router.navigate([this.returnUrl]);

      }
    })
  }

  getEcoInfo() {
    this.service.getecoinfoPublic().subscribe({
      next: (res: any) => {
        console.log('eco info', res)
        this.ecoInfo = res.data
      }
    })
  }

}
