import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
// import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/_services/app.service';
// import { TokenService } from '../../services/token.service';

enum ErrorStates {
  NotSubmitted,
  HasError,
  NoError,
}

@Component({
  selector: 'app-modal-content',
  template: `
    <div class="modal-body text-center p-4">
      <span class="svg-icon svg-icon-primary svg-icon-5tx"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.3"
            x="2"
            y="2"
            width="20"
            height="20"
            rx="10"
            fill="currentColor"
          />
          <path
            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <br /><br />
      <h3>Verified!</h3>
      <p>You have successfully verified your account</p>
      <br /><br />
      <button type="button" class="btn btn-primary mb-5" (click)="continue()">
        Proceed to Dashboard
      </button>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="continue()">
        Proceed to Dashboard
      </button>
    </div> -->
  `,
})
export class NgbdModalContent {
  constructor(
    config: NgbModalConfig,
    public activeModal: NgbActiveModal,
    public modal: NgbModal,
    private router: Router
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  continue() {
    // //console.log('yaaay');
    this.modal.dismissAll();
    this.router.navigateByUrl('/home');
  }
}

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  errorState: ErrorStates = ErrorStates.NotSubmitted;
  errorStates = ErrorStates;
  isLoading$: Observable<boolean> | undefined;

  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  otp!: string;
  dasabled: boolean = true;
  message: any;
  returnUrl: any;
  displayotpbox!: boolean;
  theme!: string;

  constructor(
    private settings: AppService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {
    let userdata = this.settings.getAuthFromLocalStorage();
    if (!userdata) {
      this.router.navigate(['/login']);
    }
    if (userdata) {
      if (userdata !== null || userdata !== undefined) {
        if (userdata?.data?.customer?.email_verified_at !== null) {
          this.returnUrl =
            this.route.snapshot.queryParams['returnUrl'.toString()] || '/home';
          this.router.navigate([this.returnUrl]);
        }
      }
    }
  }

  ngOnInit(): void {
    this.getTheme()
  }

  getTheme() {
    if (localStorage.getItem('theme') == null) {
      this.theme = 'dark-theme';
      localStorage.setItem('theme', 'dark-theme');
      document.body.classList.add('dark-theme');
    } else {
      this.theme = localStorage.getItem('theme') || 'light';
    }

  }
  onCodeChanged(code: string) { }

  onCodeCompleted(code: string) {
    this.dasabled = false;
    this.otp = code;
  }

  submit() {
    this.errorState = ErrorStates.NotSubmitted;
    console.log('ro ra naw', this.otp);
    const data = { otp: this.otp };
    const verifyEmailSubscr = this.settings.verifyEmail(data).subscribe({
      next: (res: any) => {
        this.displayotpbox = true;
        this.settings.setAuthFromLocalStorage(res);
        this.router.navigateByUrl(this.returnUrl || '/home');
        //   let ngbModalOptions: NgbModalOptions = {
        //     backdrop : 'static',
        //     keyboard : false
        //  };
        // const modalRef = this.modalService.open(NgbdModalContent, ngbModalOptions);
      },
      error: (err: any) => {
        Swal.fire('Failed', `${err?.error?.errors?.email?.[0]}`, 'error');
      },
    });
  }
  // Cancel function
  cancel() {
    this.settings.logout();
    // this.router.navigate(['auth/login'])
  }

  resendOTP() {
    this.settings
      .resendOTP()
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          //console.log(result);
          this.errorState = result ? ErrorStates.NoError : ErrorStates.HasError;
          this.message = result.message;
        },
      });
  }

}
