import { Component } from '@angular/core';
import { AppService } from '../_services/app.service';

@Component({
  selector: 'app-redeemed-offers',
  templateUrl: './redeemed-offers.component.html',
  styleUrls: ['./redeemed-offers.component.scss']
})
export class RedeemedOffersComponent {
  redeemedOffers: any = [];
  page = 1
  total: any;
  per_page: any;
  ecoInfo: any;

  constructor(
    private app: AppService
  ) {
    this.getEcoInfo()
    this.viewRedeemedOffers(this.page)
  }


  viewRedeemedOffers(page: number) {
    this.app.viewRedeemedOffers(page).subscribe({
      next: (res: any) => {
        console.log(res)
        this.redeemedOffers = res.data.data
        const meta = res.data.meta
        this.total = meta.total
        this.per_page = meta.per_page
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getPage(ev: number) {
    this.page = ev
    this.viewRedeemedOffers(this.page)
  }

  access(item: any) {
    let url = item.reward_data.redirect_url;
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.click();
    document.body.removeChild(a);
  }

  getEcoInfo() {
    this.app.getecoinfo().subscribe({
      next: (res: any) => {
        console.log('eco info', res)
        this.ecoInfo = res.data
      }
    })
  }
}
