import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CodeInputModule } from 'angular-code-input';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HttpClientModule } from '@angular/common/http';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AngularToastifyModule } from 'angular-toastify';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SettingsComponent } from './settings/settings.component';
import { authInterceptorProviders } from './_services/http.interceptor';
import { RedeemedOffersComponent } from './redeemed-offers/redeemed-offers.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { ActivitiesComponent } from './activities/activities.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    SettingsComponent,
    RedeemedOffersComponent,
    LeaderboardComponent,
    ActivitiesComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CodeInputModule,
    BrowserModule,
    NgMultiSelectDropDownModule,
    NgbModule,
    HttpClientModule,
    // AlertModule.forRoot({ maxMessages: 5, timeout: 5000, positionX: 'right' }),
    // HdWalletAdapterModule.forRoot({ autoConnect: true }),
    LoadingBarModule,
    FormsModule,
    ReactiveFormsModule,
    // OrderModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // NgMarqueeModule,
    // CarouselModule,
    AngularToastifyModule,
    // NgApexchartsModule,
    NgxPaginationModule,
    // FilterPipeModule,
    LoadingBarHttpClientModule,
    // PickerModule,
    InfiniteScrollModule,
    NgbDropdownModule
    // CodeInputModule,
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
