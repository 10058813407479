import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../_services/app.service';
import Swal from 'sweetalert2';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isLoading$: Observable<boolean> | undefined;
  user$!: Promise<any>;
  profile_pic = 'assets/imgs/avatar2.png';
  collapsed = true;
  name: any;
  pageOffers: any;
  balance: any;
  item: any;
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading!: boolean;
  private unsubscribe: Subscription[] = [];
  loading: boolean = false;
  ecoInfo: any;
  startloading: boolean = true;
  constructor(config: NgbDropdownConfig, public app: AppService, private modal: NgbModal) {
    config.placement = 'bottom-right';
    config.autoClose = false;

    this.isLoading$ = this.app.isLoading$;
    const loadingSubscr = this.isLoading$$
      .asObservable()
      .subscribe((res) => (this.isLoading = res));
    this.unsubscribe.push(loadingSubscr);

  }

  ngOnInit(): void {
    // console.log(this.isLoading$)
    this.user$ = this.app.getUser().then((res: any) => {
      console.log(res)

      if (res?.sc_profile_picture !== '') {
        this.profile_pic = res?.sc_profile_picture
        this.name = res?.name

      }
    })
    this.getBalance()
    this.getAllOffers()
    this.getEcoInfo()
  }

  getBalance() {
    this.app.getEcoSystemBalance().subscribe({
      next: (res: any) => {
        console.log(res)
        this.balance = res.data
      },
      error: (err: any) => {
        console.log(err)

        setTimeout(() => {
          this.getBalance()
        }, 5000);
      }
    })
  }

  makePayment() {
    this.loading = true
    // console.log(this.isLoading$)
    if (this.item !== null) {
      const a = {
        payment_id: this.item?.page_link
      }

      this.app.makePayment(a).subscribe({
        next: (res: any) => {
          console.log(res)
          let message = res.data?.success_message
          if (message === null || message === '') {
            message = res.message
          }
          Swal.fire('Success', message, 'success').then(() => {
            if (res.data.redirect_url !== null) {
              let url = res.data.redirect_url;
              let a = document.createElement('a');
              document.body.appendChild(a);
              a.href = url;
              a.click();
              document.body.removeChild(a);
            } else {
              this.getAllOffers()
              this.modal.dismissAll()
            }

          })
        },
        error: (err: any) => {
          this.loading = false
          console.log(err)
          Swal.fire('Failed', err.error.message, 'error')
          this.modal.dismissAll()
        },
        complete: () => {

          this.loading = false
        }
      })
    }

  }


  open(content: any, item: any) {
    this.item = item
    // console.log(content)
    // console.log(this.isLoading$)
    this.modal.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result
  }
  getAllOffers() {
    this.startloading = true
    this.app.getAllOffers().subscribe({
      next: (res: any) => {
        console.log('pageOffers', res)
        this.pageOffers = res.data
      },
      error: (err: any) => {
        console.log(err)
        this.startloading = false
      },
      complete: () => {
        this.startloading = false
      }
    })
  }

  getEcoInfo() {
    this.app.getecoinfo().subscribe({
      next: (res: any) => {
        console.log('eco info', res)
        this.ecoInfo = res.data
      }
    })
  }
}
