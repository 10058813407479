<div class="container mt-5">
  <div class="row">
    <div class="col-12">
      <h3 class="mb-0">
        <a [href]="'/home'"><i class="fa-thin fa-arrow-left"></i></a> &nbsp; Redeemed Offers
      </h3>
    </div>

    <div class="col-12 mt-5" *ngIf="redeemedOffers?.length > 0">
      <!-- <div class="row border py-2 align-items-center rounded-3 mb-3">
        <div class="col-md-4">
          <div class="d-flex align-items-center">
            <img src="assets/imgs/290.jpg" class="fixedImg me-2" alt="">
            <span>Dedicated Hot Seat</span>
          </div>
        </div>
        <div class="col-md-2">
          <span>22-01-2024</span>
        </div>
        <div class="col-md-2">
          <span>5.0$GARY</span>
        </div>
        <div class="col-md-4">
          <span>
            <a
              href="https://www.example.com/redeem?offer_code=33175">https://www.example.com/redeem?offer_code=33175</a>
          </span>
        </div>
      </div>

      <div class="row border py-2 align-items-center rounded-3 mb-3">
        <div class="col-md-4">
          <div class="d-flex align-items-center">
            <img src="assets/imgs/290.jpg" class="fixedImg me-2" alt="">
            <span>Dedicated Hot Seat</span>
          </div>
        </div>
        <div class="col-md-2">
          <span>22-01-2024</span>
        </div>
        <div class="col-md-2">
          <span>5.0$GARY</span>
        </div>
        <div class="col-md-4">
          <span>
            <a
              href="https://www.example.com/redeem?offer_code=33175">https://www.example.com/redeem?offer_code=33175</a>
          </span>
        </div>
      </div> -->

      <div class="d-none d-md-block">
        <div class="row mb-2">
          <div class="col-md-3">Name</div>
          <div class="col-md-3 text-center">Date</div>
          <div class="col-md-3 text-center">Amount</div>
          <div class="col-md-3"></div>
        </div>
      </div>
      <div class="row border py-2 px-3 align-items-center rounded-3 mb-3" *ngFor="let item of redeemedOffers | paginate
                              : {
                                  itemsPerPage: per_page,
                                  currentPage: page,
                                  totalItems: total
                                };">
        <div class="col-12 col-md-3">
          <div class="d-none d-md-flex align-items-center">
            <img [src]="item?.reward_data?.reward_image || 'assets/imgs/290.jpg'" class="fixedImg me-3" alt="">
            <span> {{ item?.reward_data?.reward_title }} </span>
          </div>
          <div class="d-block d-md-none">
            <img [src]="item?.reward_data?.reward_image || 'assets/imgs/290.jpg'" class="fixedImg me-3" alt="">
            <h3 class="mt-3">{{ item?.reward_data?.reward_title }}</h3>
          </div>
        </div>
        <div class="col-6 col-md-3 text-center">
          <span> {{ item?.date| date }} </span>
        </div>
        <div class="col-6 col-md-3 text-center ">
          <span> {{ item?.amount_charge| number:'1.0-2' }} {{ ecoInfo?.display_symbol }} </span>
        </div>

        <div class="col-6 col-md-3 text-center ">
          <button class="btn btn-custom btn-sm" *ngIf="item?.reward_data.redirect_url" (click)="access(item)">Access
            Offer</button>
        </div>
      </div>

      <div class="text-end mt-3 ">
        <pagination-controls class="d-flex justify-content-center my-pagination" [directionLinks]="true"
          [responsive]="true" (pageChange)="getPage($event)"></pagination-controls>
      </div>

    </div>

    <div class="col-12 mt-3" *ngIf="redeemedOffers?.length <= 0">
      <div class="text-center mt-5">
        <div class="row">
          <div class="col-12 border py-5 rounded">
            <p> You haven't claimed any offer at this time.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- {
"0": 0,
"1": 0,
"first_name": "Tes Sal",
"last_name": "SC",
"email": "tescointsite@gmail.com",
"phone": null,
"status": "paid",
"cryptocurrency_type": "$DEMO",
"gas_fee_bearer": "client",
"page_link": "cls3kyr730001y3vct0jipb35",
"custom_fields": [],
"payout_address": "nvdvFE7mamg3RDm6j4GEJ4zjrL8DcVNpkUav92M2rzs",
"provider_address": "CVdSH1tJmjtfdJzpGAw5V41Rx9ovJc5FWcUGzWWyt4RU",
"transaction_reference": "73RZKRzA2LmqdCePDN4MoAXyQPEvoMiMfwtqAzewrwif",
"amount_charge": "100.0000000000",
"provider_fee": "0.0000000000",
"transaction_memo": "scrp-$DEMO-2024-02-04 17:03:40-3",
"reward_data": {
"reward_title": "Demo Call",
"reward_image":
"https://pub-3a9bbf364b7e4fafb2ccf4e85d9eb22a.r2.dev/socialpay-images/cls3kyr730001y3vct0jipb350nI2A.png",
"success_message": "Hello",
"redirect_url": "https://google.com"
}
} -->
