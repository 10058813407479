<div class="container mt-5" *ngIf=" user$ as _user">
  <div class="row">
    <div class="col-12">
      <h3><a [href]="'/home'"><i class="fa-thin fa-arrow-left"></i></a> &nbsp; Settings</h3>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-md-4 col-lg-3">
      <div class="card">
        <ul class="list-group list-group-flush border" style="cursor: pointer !important;">
          <li class="list-group-item " (click)="setNav(1)" [ngClass]="navPosition === 1 ? 'active': '' ">
            <div class="d-flex justify-content-center">
              <span class="me-3"><i class="fa-regular fa-circle-user fa-lg"></i></span>
              <h6 class="mb-0">Account Information</h6>
              <span class="ms-auto me-3"><i class="fa-regular fa-angle-right fa-lg"></i></span>
            </div>
          </li>
          <li class="list-group-item" (click)="setNav(2)" [ngClass]="navPosition === 2 ? 'active': '' ">
            <div class="d-flex justify-content-center">
              <span class="me-3"><i class="fa-light fa-key fa-lg"></i></span>
              <h6 class="mb-0">Password</h6>
              <span class="ms-auto me-3"><i class="fa-regular fa-angle-right fa-lg"></i></span>
            </div>
          </li>
          <li class="list-group-item" (click)="setNav(3)" [ngClass]="navPosition === 3 ? 'active': '' ">
            <div class="d-flex justify-content-center">
              <span class="me-3"><i class="fa-duotone fa-link fa-lg"></i></span>
              <h6 class="mb-0">Link Socials</h6>
              <span class="ms-auto me-3"><i class="fa-regular fa-angle-right fa-lg"></i></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-8 col-lg-9">
      <div *ngIf="navPosition===1">
        <div class="card transcard">
          <div class="card-body p-5">
            <div class="text-center mb-5">
              <span class="logo">
                <input class="test" #imageInput type="file" accept="image/*" id="file"
                  (change)="processFile(imageInput)" />
                <label for="file" style="cursor: pointer" placement="bottom">
                  <img [src]="imgFile" alt="">
                </label>

                <span class="icononImg">
                  <i class="fa-solid fa-circle-camera fa-lg"></i>
                </span>
              </span>
            </div>
            <p>Full Name</p>
            <div class="form-group mt-3 mb-5 border p-3 rounded">
              <div class="row">
                <div class="col-12 col-md-8 col-lg-9 mb-3 mb-md-0">
                  <input type="text" class="custom_input" [(ngModel)]="name">
                </div>
                <div class="col-12 col-md-4 col-lg-3 text-end">
                  <button class="btn btn-outline-white rounded-pill px-4 btn-sm" (click)="upload()">Change</button>
                </div>
              </div>
            </div>

            <p>Username</p>
            <div class="form-group mt-3 mb-5 border p-3 rounded">
              <div class="row">
                <div class="col-12 col-md-8 col-lg-9 mb-3 mb-md-0">
                  <input type="text" class="custom_input" [(ngModel)]="social_tag">
                </div>
                <div class="col-12 col-md-4 col-lg-3 text-end">
                  <button class="btn btn-outline-white rounded-pill px-4 btn-sm" (click)="Submit()">Change</button>
                </div>
              </div>
            </div>

            <p>Email Address</p>
            <div class="form-group my-3 border p-3 rounded " *ngIf="!showUpdateEmail">
              <div class="row">
                <div class="col-12 col-md-8 col-lg-9 mb-3 mb-md-0">
                  <input type="text" class="custom_input" [(ngModel)]="email" disabled>
                </div>
                <div class="col-12 col-md-4 col-lg-3 text-end">
                  <button class="btn btn-outline-white rounded-pill px-4 btn-sm" (click)="changeEmail()">Change</button>
                </div>
              </div>
            </div>

            <div *ngIf="showUpdateEmail" class="form-group my-3 border p-3 rounded ">
              <form [formGroup]="changeMailForm" (ngSubmit)="saveEmail()">
                <div class="row align-items-end">
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for="" class="form-label">Email</label>
                      <input type="email" class="form-control form-control-solid form-control-lg" name="email"
                        formControlName="email" autocomplete="off" [value]="email" [ngClass]="{
                        'is-invalid': changeMailForm.controls['email'].invalid,
                        'is-valid': changeMailForm.controls['email'].valid
                      }">
                    </div>
                    <!-- <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'required',
                                                message: 'Email is required',
                                                control: changeMailForm.controls['email']
                                              }"></ng-container>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'email',
                                                message: 'Email is invalid',
                                                control: changeMailForm.controls['email']
                                              }"></ng-container>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'minlength',
                                                message: 'Email should have at least 3 symbols',
                                                control: changeMailForm.controls['email']
                                              }"></ng-container>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'maxLength',
                                                message: 'Email should have maximum 360 symbols',
                                                control: changeMailForm.controls['email']
                                              }"></ng-container>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for="" class="form-label">Password</label>
                      <input type="password" class="form-control form-control-solid form-control-lg" name="password"
                        formControlName="password" autocomplete="off" [ngClass]="{
                      'is-invalid': changeMailForm.controls['password'].invalid,
                      'is-valid': changeMailForm.controls['password'].valid
                    }">
                    </div>
                    <!-- <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                              validation: 'required',
                                              message: 'Password is required',
                                              control: changeMailForm.controls['password']
                                            }"></ng-container>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                              validation: 'minlength',
                                              message: 'Password should have at least 8 symbols',
                                              control: changeMailForm.controls['password']
                                            }"></ng-container>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                              validation: 'maxLength',
                                              message: 'Password should have maximum 100 symbols',
                                              control: changeMailForm.controls['password']
                                            }"></ng-container>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-lg-4 mb-3">
                    <button type="submit" class="btn btn-custom btn-sm me-2 btn-sm"
                      [disabled]="changeMailForm.invalid">Submit</button>
                    <button type="button" class="btn btn-sm btn-clear btn-sm" (click)="cancel()">Cancel</button>
                  </div>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>



      <div *ngIf="navPosition===2">
        <div class="card transcard">
          <div class="card-body p-5">
            <div class="text-center my-5">
              <h3>Change Password</h3>
            </div>
            <div class="row my-4">
              <div class="col-md-10 offset-md-1">
                <form [formGroup]="changePasswordForm" (ngSubmit)="savePassword()">
                  <div class="row fv-row mb-5">
                    <label class="form-label fw-bolder">Old Password
                    </label>
                    <div class="input-group">
                      <span class="input-group-text">
                        <button type="button" class="btn btn-clear" (click)="showPass = !showPass">
                          <i class="fa-solid " [class]="showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
                        </button>
                      </span>
                      <input class="form-control form-control-solid" placeholder="Old Password"
                        type="{{ showPass ? 'text' : 'password' }}" />

                    </div>


                  </div>


                  <div class="row fv-row mb-5">
                    <label class="form-label fw-bolder">New Password
                    </label>
                    <div class="input-group">
                      <span class="input-group-text">
                        <button type="button" class="btn btn-clear" (click)="showPass = !showPass">
                          <i class="fa-solid " [class]="showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
                        </button>
                      </span>
                      <input class="form-control form-control-solid" type="{{ showPass ? 'text' : 'password' }}"
                        name="password" formControlName="password" placeholder="New Password" [ngClass]="{
                      'is-invalid': changePasswordForm.controls['password'].invalid,
                      'is-valid': changePasswordForm.controls['password'].valid
                    }" />

                    </div>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                                  validation: 'required',
                                                                  message: 'Password is required',
                                                                  control: changePasswordForm.controls['password']
                                                                }"></ng-container>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                                  validation: 'minlength',
                                                                  message: 'Password should have at least 8 symbols',
                                                                  control: changePasswordForm.controls['password']
                                                                }"></ng-container>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                                  validation: 'maxLength',
                                                                  message: 'Password should have maximum 100 symbols',
                                                                  control: changePasswordForm.controls['password']
                                                                }"></ng-container>
                      </div>
                    </div>

                  </div>

                  <div class="row fv-row mb-5">
                    <label class="form-label fw-bolder">Confirm Password
                    </label>
                    <div class="input-group">
                      <span class="input-group-text">
                        <button type="button" class="btn btn-clear" (click)="showPass2 = !showPass2">
                          <i class="fa-solid " [class]="showPass2 ? 'fa-eye-slash' : 'fa-eye'"></i>
                        </button>
                      </span>
                      <input class="form-control form-control-solid" type="{{ showPass2 ? 'text' : 'password' }}"
                        placeholder="Confirm password" name="password_confirmation" autocomplete="off"
                        formControlName="password_confirmation" [ngClass]="{
                          'is-invalid': changePasswordForm.controls['password_confirmation'].invalid,
                          'is-valid': changePasswordForm.controls['password_confirmation'].valid
                        }" />

                    </div>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                                          validation: 'required',
                                                                          message: 'Confirm Password is required',
                                                                          control: changePasswordForm.controls['password_confirmation']
                                                                        }"></ng-container>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                                          validation: 'minlength',
                                                                          message: 'Confirm Password should have at least 3 symbols',
                                                                          control: changePasswordForm.controls['password_confirmation']
                                                                        }"></ng-container>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                                          validation: 'maxLength',
                                                                          message: 'Confirm Password should have maximum 100 symbols',
                                                                          control: changePasswordForm.controls['password_confirmation']
                                                                        }"></ng-container>
                        <ng-container *ngIf="
                                                                          changePasswordForm.controls['password_confirmation'].errors &&
                                                                          changePasswordForm.controls['password_confirmation'].errors['ConfirmPassword']
                                                                        ">
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              'Passsword' and 'Confirm Password' didn't match.
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                  </div>

                  <button type="submit" class="btn btn-custom btn-block">Submit</button>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div *ngIf="navPosition === 3">
        <div class="card transcard">
          <div class="card-body p-md-5 p-4">
            <div class="row border p-2 align-items-center mb-3">
              <div class="col-2 text-center">
                <img src="assets/svg/x-logo.svg" class="img-fluid socials">
              </div>
              <div class="col-6">
                <h5 class="">X</h5>
                <span *ngIf="!twitterActive">
                  <p class="fs-7 mb-0">Link your twitter to get access to giveaways and drops</p>
                </span>
                <span *ngIf="twitterActive">
                  <h6 class="mb-0">{{ userDetails?.twitter_username }}</h6>
                </span>

              </div>

              <div class="col-4">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-outline-white btn-sm rounded-pill" *ngIf="!twitterActive"
                    (click)="linkTwitter()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Link</span>
                    </ng-container>
                  </button>
                  <button class="btn btn-outline-white btn-sm rounded-pill" *ngIf="twitterActive"
                    (click)="unlinkTwitter()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Unlink</span>
                    </ng-container>
                  </button>
                </div>

              </div>
            </div>

            <div class="row border p-2 align-items-center mb-3">
              <div class="col-2 text-center">
                <img src="assets/svg/discord-logo.svg" class="img-fluid socials">
              </div>
              <div class="col-6">
                <h5 class="">Discord</h5>
                <span *ngIf="!discordactive">
                  <p class="fs-7 mb-0">Link your discord to access our bots</p>
                </span>
                <span *ngIf="discordactive">
                  <h6 class="mb-0"> {{ discord_data?.username }}#{{
                    discord_data?.discriminator
                    }}</h6>
                </span>
              </div>

              <div class="col-4">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-outline-white btn-sm rounded-pill" *ngIf="!discordactive"
                    (click)="linkDiscord()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Link</span>
                    </ng-container>
                  </button>
                  <button class="btn btn-outline-white btn-sm rounded-pill" *ngIf="discordactive"
                    (click)="unlinkDiscord()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Unlink</span>
                    </ng-container>
                  </button>
                </div>

              </div>
            </div>

            <div class="row border p-2 align-items-center mb-3">
              <div class="col-2 text-center">
                <img src="assets/svg/telegram-logo.svg" class="img-fluid socials">
              </div>
              <div class="col-6">
                <h5 class="">Telegram</h5>
                <span *ngIf="!telactive">
                  <p class="fs-7 mb-0">Link your Telegram to access our Telegram features</p>
                </span>
                <span *ngIf="telactive">
                  <h6 class="mb-0">{{ userDetails?.telegram_user_info?.username }}</h6>
                </span>
              </div>

              <div class="col-4">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-outline-white btn-sm rounded-pill" *ngIf="!telactive" (click)="linkTelegram()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Link</span>
                    </ng-container>
                  </button>
                  <button class="btn btn-outline-white btn-sm rounded-pill" *ngIf="telactive"
                    (click)="unlinkTelegram()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Unlink</span>
                    </ng-container>
                  </button>
                </div>
              </div>
            </div>

            <!-- <div class="row border p-2 align-items-center mb-3">
              <div class="col-md-2 text-center">
                <img src="assets/svg/facebook_logo.svg" class="img-fluid socials">
              </div>
              <div class="col-md-4">
                <h5 class="mb-0">Facebook</h5>
              </div>
              <div class="col-md-4 text-end">
                <h6>John doe</h6>
              </div>
              <div class="col-md-2">
                <button class="btn btn-outline-white btn-sm rounded-pill">Link</button>
              </div>
            </div> -->

            <!-- <div class="row border p-2 align-items-center mb-3">
              <div class="col-2 text-center">
                <img src="assets/svg/instagram_logo.svg" class="img-fluid socials">
              </div>
              <div class="col-6">
                <h5 class="mb-0">Instagram</h5>
                <span *ngIf="!ig_activated">
                  <p class="fs-7 mb-0">Link your Instagram account to access our Instagram features</p>
                </span>
                <span *ngIf="ig_activated">
                  <h6 class="mb-0">{{ instagram_data }}</h6>
                </span>
              </div>

              <div class="col-4">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-outline-white btn-sm rounded-pill" *ngIf="!ig_activated"
                    (click)="linkInstagram()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Link</span>
                    </ng-container>
                  </button>
                  <button class="btn btn-outline-white btn-sm rounded-pill" *ngIf="ig_activated"
                    (click)="unlinkInstagram()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Unlink</span>
                    </ng-container>
                  </button>
                </div>

              </div>
            </div> -->

            <!-- <div class="row border p-2 align-items-center mb-3" *ngIf="manageInstagramBusinessAccessGranted">
              <div class="col-2 text-center">
                <img src="assets/svg/instagram_logo.svg" class="img-fluid socials">
              </div>
              <div class="col-6">
                <h5 class="mb-0">Instagram Business</h5>
                <span *ngIf="insta_check?.instagram_business_linked === true">
                  <h6 class="mb-0">{{ instagram_business_username }}</h6>
                </span>
              </div>

              <div class="col-4">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-outline-white btn-sm rounded-pill"
                    *ngIf="insta_check?.instagram_business_linked === false" (click)="linkInstagramBusiness()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Link</span>
                    </ng-container>
                  </button>
                  <button class="btn btn-outline-white btn-sm rounded-pill"
                    *ngIf="insta_check?.instagram_business_linked === true" (click)="unlinkInstagramBusiness()">
                    <ng-container *ngIf="isLoading$ | async">
                      <span class="indicator-progress" [style.display]="'block'">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="(isLoading$ | async) === false">
                      <span class="indicator-label">Unlink</span>
                    </ng-container>
                  </button>
                </div>
              </div>
            </div> -->

            <!-- <div class="row border p-2 align-items-center mb-3">
              <div class="col-md-2 text-center">
                <img src="assets/svg/solana_logo.svg" class="img-fluid socials">
              </div>
              <div class="col-md-4">
                <h5 class="mb-0">Solana</h5>
              </div>
              <div class="col-md-4 text-end">
                <h6>John doe</h6>
              </div>
              <div class="col-md-2">
                <button class="btn btn-outline-white btn-sm rounded-pill">Link</button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>


  </div>


  <ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <span role="alert">
            {{ message }}
          </span>
        </div>
      </div>
    </ng-container>
  </ng-template>