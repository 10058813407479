import { ChangeDetectorRef, Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { DummyJsonResponse } from '../models/models';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from 'angular-toastify';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

const API_USERS_URL = `${environment.apiUrl}/auth`;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const api = `${environment.base_api}`
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

export type UserType = any | undefined;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private authLocalStorageToken = `eco_user`;
  private userLocalStorageToken = `eco_userdetails`;
  isLoading$!: Observable<boolean>;
  isLoadingSubject!: BehaviorSubject<boolean>;
  private buttonClickSubject = new Subject<void>();
  verify_Otp: boolean = false;
  buttonClick$ = this.buttonClickSubject.asObservable();
  userdata: any;
  user: any;
  emailChanged_res: any;

  triggerButtonClick(param: any) {
    this.buttonClickSubject.next(param);
  }

  token: any;
  activityLogs: any = [];
  errorMessage: string = '';
  commenting: boolean = false;
  imageErrValue!: string;
  imageError!: boolean;
  qqq!: boolean;
  maxFileSize = 3 * 1024 * 1024;
  selectedFile!: ImageSnippet;
  imgFile: any;
  // interval!: NodeJS.Timer;
  currentUserSubject!: BehaviorSubject<UserType>;
  currentUser$!: Observable<UserType>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private sanitizer: DomSanitizer,
    // private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private modal: NgbModal,
  ) {
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoadingSubject = new BehaviorSubject<boolean>(true);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  login(data: any) {
    return this.http.post(`${API_USERS_URL}/login`, data)
      .pipe(
        map((user: any) => {
          console.log('user', user)
          if (user?.data?.otp_required === true) {
            this.verify_Otp = true;
            this.userdata = user;
            return user
          } else {
            const result = this.setAuthFromLocalStorage(user);
            this.getUserByToken(user.data.token);
            return user;
          }
        }),
        catchError((err) => {
          return of(err);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  registration(user: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.createUser(user).pipe(
      map((rs) => {
        return rs;
      }),
      switchMap(() => this.login(user)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  createUser(user: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/register`, user);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/forgot-password`, { email, })
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }


  resetPassword(data: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/reset-password`, data)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resendOTP(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this
      .resendotp(auth.data.customer.email, auth.data.token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resendotp(email: string, token: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<any>(
      `${API_USERS_URL}/otp/resend`,
      { email },
      { headers: httpHeaders }
    );
  }

  verifyEmail(otp: any): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.verifyemail(otp, auth.data.token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getUserDetails() {
    return this.http.get<any>(`${api}/user`, httpOptions)
      .pipe(
        map((user) => {
          this.saveUserToStorage(user)
          return user
        }),
        catchError((err) => {
          return of(undefined);
        }),
        finalize(() => {
          this.getUser();
          this.isLoadingSubject.next(false);
        })
      );
  }

  verifyemail(otp: any, token: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<any>(`${API_USERS_URL}/otp/verify`, otp, {
      headers: httpHeaders,
    });
  }

  getUserByToken(token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<any>(`${environment.base_api}/user`, {
      headers: httpHeaders,
    }).pipe();
  }
  isLogedIn() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return false
    } else if (auth.data == null || auth.data.token === null) {
      return false
    }
    else return true
  }

  public setAuthFromLocalStorage(auth: any): boolean {
    if (auth) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public async saveUserToStorage(data: any): Promise<boolean> {
    if (data) {
      // console.log('dataaaaa', data)
      localStorage.setItem(this.userLocalStorageToken, JSON.stringify(data));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage() {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getUserFromStorage() {
    try {
      const userValue = localStorage.getItem(this.userLocalStorageToken);
      if (!userValue) {
        return undefined;
      }

      const userData = JSON.parse(userValue);
      return userData.permissions;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async getUser() {
    try {
      const userValue = localStorage.getItem(this.userLocalStorageToken);
      if (!userValue) {
        return undefined;
      }

      const userData = JSON.parse(userValue);
      return userData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getAuth() {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      const a = authData.data.token;
      return a;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  logout() {
    const returnUrl = location.pathname;
    localStorage.clear();
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.userLocalStorageToken);
    this.router.navigate(['/login'], {
      queryParams: { return: returnUrl },
    });
  }


  updateProfile(profile_picture: File, name: any, bio: any) {
    const formData = new FormData();
    // formData.append()
    formData.append('profile_picture', profile_picture);
    formData.append('name', name);
    formData.append('bio', bio);
    return this.http.post(`${api}/user/update-profile`, formData);
  }

  updatePassword(data: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.patch<any>(`${api}/user/change-password`, data).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  changeEmail(data: any): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.data.token}`,
    });

    return this.http.patch<any>(
      `${api}/user/update-email`,
      data,
      {
        headers: httpHeaders,
      }
    ).pipe(
      catchError((err) => {
        console.error('err', err);
        if (err.error.error?.email[0]) {
          const message = err.error.error?.email[0];
          Swal.fire('Failed', message, 'error');
        } else {
          Swal.fire('Failed', err.error.message, 'error');
        }
        return of(err);
      }),
      map((res) => {
        this.emailChanged_res = res;
        if (res.success === true) {
          Swal.fire('Success', res.message, 'success');
          localStorage.removeItem(this.authLocalStorageToken);
          this.router.navigate(['/']);
          return res;
        }
      }),
      switchMap((): any => {
        console.log(this.emailChanged_res);
        if (this.emailChanged_res['success'] === true) {
          console.log(this.emailChanged_res, 'login function next');
          return this.login(data);
        } else if (this.emailChanged_res['success'] === false) {
          console.log(this.emailChanged_res)
          return {};
        }
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }


  getAllOffers() {
    return this.http.get(`${api}/social-pay/ecosystem-payment-pages-public`).pipe()
  }
  viewRedeemedOffers(page: number) {
    return this.http.get(`${api}/social-pay/payment-pages/redeemed-offers?page=${page}`).pipe()
  }

  getEcoSystemBalance() {
    return this.http.get(`${api}/social-connector/portfolio/ecosystem-balance`).pipe()
  }

  makePayment(data: any) {
    this.isLoadingSubject.next(true);
    return this.http.post(`${api}/social-pay/process-ecosystem-transaction`, data).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    )
  }


  unlinkTwitter(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-twitter`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  linkTwitter(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-twitter-v2`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  linkTelegram(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-telegram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  unlinkTelegram(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-telegram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  unlinkDiscord(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-discord`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  linkDiscord(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-discord`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  linkInstagram(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-instagram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  unlinkInstagram(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-instagram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // instagram business services start
  linkInstagramBusiness(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-business-instagram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  unlinkInstagramBusiness(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-business-instagram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }


  getecoinfo(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/ecosystem-info`).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getecoinfoPublic(): Observable<any> {
    const origin = window.location.origin
    const httpHeaders = new HttpHeaders({
      ecosystem: `${origin}`,
    });
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/ecosystem-info-public`, { headers: httpHeaders }).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // https://gatewaytesting.socialconnector.io/api/v2/ecosystem-info-public

  getLeaderBoardToken(token: any) {
    return this.http.get(environment.base_api + `/exchange/leaderboard/ecosystem/${token}`)
  }

  getBadges(token: string) {
    return this.http.get(`${environment.base_api}/exchange/nft-badges/${token}`).pipe()
  }
  getActivities(page: number) {
    return this.http.get(`${environment.base_api}/solana/ecosystem-transfers?page=${page}`).pipe()
  }

  socialTag(data: any) {
    return this.http.post(`${api}/user/create-social-tag`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

}
