<div class="container mt-5">

  <div class="row">
    <div class="col-12">
      <h3 class="mb-0">
        <a [href]="'/home'"><i class="fa-thin fa-arrow-left"></i></a> &nbsp; Leaderboard
      </h3>
    </div>
  </div>
  <div class="card mt-5">
    <div class="card-header">
      <div class="row">
        <div class="col-md-4 offset-md-8">

          <form [formGroup]="myForm">
            <ng-multiselect-dropdown name="badge" [placeholder]="'Select a badge to see who has it'" [data]="badges"
              formControlName="badge" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
              (onDeSelect)="onItemSelect($event)">
            </ng-multiselect-dropdown>
          </form>
        </div>
      </div>
    </div>
    <div class="table-responsive">

      <table class="table table-hover align-middle table-lg">
        <thead>
          <tr>
            <th class="ps-5 text-end py-3" style="width: 10px;">#</th>
            <th class="py-3" style="width: 10%;">
              Name
            </th>
            <th class="py-3">Badges</th>
            <th class="text-end pe-5 py-3"> {{ ecoInfo?.display_symbol }} Balance </th>
          </tr>
        </thead>
        <tbody>

          <tr class="px-5"
            *ngFor="let item of filterdLeaderboard  | paginate: { id:'leader', itemsPerPage: perpage, currentPage: page, totalItems: size}; ">
            <td class="ps-5 text-end" style="width: 10px;">
              <div class="d-flex align-items-center">
                <div class="icon">
                  <p>&nbsp; </p>
                  <p>

                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'down'">
                      <i class="fa-solid text-danger fa-arrow-down"></i>
                    </span>
                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'up'">
                      <i class="fa-solid text-success fa-arrow-up"></i>
                    </span>
                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'same'">
                      <i class="fa-solid text-dark fa-minus"></i>
                    </span>
                    <!-- <span *ngIf="item?.movement === 0 ">
                      <i class="fa-solid text-dark fa-minus"></i>
                    </span> -->
                  </p>

                </div>
                <div class="d-block">
                  <p class="mb-0">
                    {{item?.current_position}}
                  </p>
                  <p class="mb-0">
                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'down'" class="text-danger">
                      {{ item?.movement }}
                    </span>
                    <span *ngIf="item?.movement  !== 0 && item?.direction == 'up'" class="text-success">
                      {{ item?.movement }}
                    </span>


                  </p>
                </div>
              </div>

            </td>
            <td style="width: 20%;">
              <a class="name" *ngIf="item?.user?.social_tag  !== undefined">
                <div class="d-flex align-items-center">
                  <div class="img">
                    <img *ngIf="!item?.user?.profile_picture" width="40" height="40" class="rounded-circle"
                      src="assets/imgs/badge.png" alt="">
                    <img *ngIf="item?.user?.profile_picture" [src]=" item?.user?.profile_picture " alt="" srcset=""
                      onerror="this.onerror=null;
                                            this.src='assets/imgs/badge.png';">
                  </div>
                  <div class="d-block">
                    <h4 class="name"> {{item?.user?.name}} </h4>

                  </div>

                </div>
              </a>
              <div *ngIf="item?.user?.social_tag  === undefined" class="d-flex align-items-center">
                <div class="img">
                  <img *ngIf="!item?.user?.profile_picture" width="40" height="40" class="rounded-circle"
                    src="assets/imgs/badge.png" alt="">
                  <img *ngIf="item?.user?.profile_picture" [src]=" item?.user?.profile_picture " alt="" srcset=""
                    onerror="this.onerror=null;
                                                          this.src='assets/imgs/badge.png';">
                </div>
                <div class="d-block">
                  <h4 class="name"> {{item?.user?.name}} </h4>

                </div>

              </div>
            </td>
            <td class="py-3">
              <div class="d-flex flex-row">
                <div class="d-flex flex-col me-2" *ngFor="let item of item.badges.slice(0,5)">
                  <span class="">
                    <ng-template class="text-center " #tipContent>
                      <h4 class="mb-3"> {{ item?.badge_name }} </h4>
                      <p [innerHTML]="item?.description"> </p>
                    </ng-template>
                    <img [src]="item.badge_image" width="60" height="60" class="" alt="" [ngbTooltip]="tipContent">
                  </span>

                </div>
                <span>
                  <ng-template class="text-start" #hoverContent>
                    <ul class="text-start list-unstyled">
                      <li *ngFor="let itemss of item.badges.slice(5)" class="mb-2">
                        <img [src]="itemss?.badge_image" width="30" height="30" class="" alt="">
                        {{ itemss?.badge_name }}
                      </li>
                    </ul>
                  </ng-template>
                </span>
                <div *ngIf="item.badges?.length > 5" class="mp-4 rounded-circle bg-secondary" style="cursor: pointer;"
                  [ngbTooltip]="hoverContent">+{{
                  item.badges?.length - 5 }}</div>
              </div>
            </td>
            <td class="text-end pe-5">
              <span class="text-color">
                {{ item?.balance | number}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="text-center mt-3 " *ngIf="!loading">
    <button *ngIf="!hideLoad" class="btn btn-clear btn-sm text-primary me-3" (click)="loadLess()">Show Less <i
        class="fa-solid fa-chevron-up"></i></button>
    <button *ngIf="!hideMore" class="btn btn-clear btn-sm text-primary" (click)="loadMore()">Load More <i
        class="fa-solid fa-chevron-down  text-primary"></i></button>
  </div>
  <!-- leader board section table end -->

</div>
