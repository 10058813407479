<div class="container mt-5 mb-5">
  <div class="row">
    <div class="col-12">
      <h3 class="mb-0">
        <a [href]="'/home'"><i class="fa-thin fa-arrow-left"></i></a> &nbsp; Activities
      </h3>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Amount</th>
              <th>Date</th>
              <th style="width:40%">Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of activities | paginate
                              : {
                                  itemsPerPage: per_page,
                                  currentPage: page,
                                  totalItems: total
                                };">
              <td class="py-3">Received {{ ecoInfo?.coin_label }} </td>
              <td class="py-3">{{item.amount | number}} {{ ecoInfo?.display_symbol }} </td>
              <td class="py-3"> {{item.date_entered| date : 'MM-dd-YYYY'}} </td>
              <td class="py-3">
                {{ item.message }}

              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-end mt-3 ">
        <pagination-controls class="d-flex justify-content-center my-pagination" [directionLinks]="true"
          [responsive]="true" (pageChange)="getPage($event)"></pagination-controls>
      </div>
    </div>
  </div>


</div>
<!-- style="width:40%" -->
