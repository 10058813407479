<nav class="navbar navbar-expand-sm navbar-light mt-4">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <!-- <img src="assets/imgs/garylogo.png" width="50" alt="" *ngIf="theme == 'light'" />
      <img src="assets/imgs/garylogowhite.png" width="50" alt="" *ngIf="theme != 'light'" /> -->
    </a>


  </div>
</nav>


<div class="container mt-3" [ngClass]="loginFormClass === true ? 'showForm' : 'hideForm'">
  <div class="row text-center">
    <div class="logo" *ngIf="ecoInfo">
      <img [src]="ecoInfo?.logo" class="img-fluid" alt="">
    </div>
    <div class="col-lg-4  offset-lg-4 col-md-6 offset-md-3 col-sm-12">
      <h3>Login</h3>
      <!-- <p>A Crypto Creator Community</p> -->
      <p class="mb-3">Login with your Social Connector account</p>

      <form [formGroup]="joinForm ">
        <div class="text-start">
          <div class="fv-row mb-7">
            <label class="form-label fw-bolder ">Email</label>
            <div class="input-group">
              <span class="input-group-text">
                <button type="button" class="btn btn-clear">
                  <i class="fa-sharp fa-solid fa-envelope"></i>
                </button>
              </span>
              <input class="form-control form-control-lg form-control-solid" type="email" placeholder="Email"
                name="email" formControlName="email" autocomplete="off" />
            </div>

          </div>

          <div class="row fv-row mb-7">
            <label class="form-label fw-bolder">Password
            </label>
            <div class="input-group">
              <span class="input-group-text">
                <button type="button" class="btn btn-clear" (click)="showPass = !showPass">
                  <i class="fa-solid " [class]="showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
              </span>
              <input class="form-control form-control-lg form-control-solid" type="{{ showPass ? 'text' : 'password' }}"
                name="password" formControlName="password" autocomplete="off" placeholder="Password" />
            </div>

          </div>
        </div>
        <div class="text-start my-3">
          <a [href]="'/forgot_password'">Forgot Password?</a>
        </div>
        <button class="btn btn-block btn-custom" (click)="login()" [disabled]="joinForm.invalid">Login</button>
      </form>

      <!-- <p class="mt-3">Don't have an account? <a [href]="'/signup/'+referral"> Create Account</a></p> -->


    </div>
  </div>
</div>


<div class="container mt-5" [ngClass]="loginFormClass === false ? 'showForm' : 'hideForm'">
  <div class="row text-center">
    <div class="col-lg-4  offset-lg-4 col-md-6 offset-md-3 col-sm-12">
      <!-- <h3>Welcome to Gary Club</h3> -->
      <!-- <p>A Crypto Creator Community</p> -->
      <!-- <p>Login with your Social Connector account or create one to get started</p> -->
      <!-- <p>A Crypto Creator Community</p> -->
      <h5>Please enter 2FA code</h5>
      <div class="text-start mt-5">

        <div class="fv-row mb-10">
          <label class="form-label fw-bolder text-gray-900 fs-6">Enter otp</label>
          <code-input [isCodeHidden]="false" [codeLength]="6" (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>

        <div class="text-center">
          <a class="btn btn-clear me-3" (click)="cancel()">Cancel</a>
          <button type="submit" class="btn btn-custom my-3" (click)="verifyOtp()">

            <ng-container>
              <span class="indicator-label">Continue</span>
            </ng-container>
          </button>
          <!-- <button class="btn btn-custom my-3" [disabled]="resetPasswordForm.invalid">Continue</button> -->
        </div>
      </div>

      <!-- <a [href]="'/forgot_password'">Forgot Password?</a> -->




    </div>
  </div>
