import { Component, OnInit } from '@angular/core';
import { AppService } from '../_services/app.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
  activities: any;
  per_page: any;
  total: any;
  page = 1
  ecoInfo: any;

  constructor(
    private app: AppService
  ) { }
  ngOnInit(): void {
    this.getActivities()
    this.getEcoInfo()
  }

  getActivities() {
    this.app.getActivities(this.page).subscribe({
      next: (res: any) => {
        console.log(res)
        this.activities = res.data.data
        const meta = res.data.meta
        this.per_page = meta.per_page
        this.total = meta.total
      }
    })
  }

  getEcoInfo() {
    this.app.getecoinfo().subscribe({
      next: (res: any) => {
        console.log('eco info', res)
        this.ecoInfo = res.data
      }
    })
  }

  getPage(ev: any) {
    this.page = ev
    this.getActivities()
  }
}
